import React from 'react';
import classnames from 'classnames';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './ComparisonChart.scss';

const features = [
  ({ plan, index }) => {
    const additionalClasses = [
      'aaa-comparison__package-title-blue',
      'aaa-comparison__package-title-red',
      'aaa-comparison__package-title-average',
    ];
    return (
      <div
        className={classnames('aaa-comparison__package-title', {
          [additionalClasses[index]]: true,
        })}
      >
        {plan.title}
      </div>
    );
  },
  ({ plan }) => (
    <div className="aaa-comparison__package-subtitle">{plan.subtitle}</div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-price">{plan.price}</div>
  ),
  ({ plan }) => (
    <div className="aaa-comparison__package-period">{plan.billingPeriod}</div>
  ),
];

const ComparisonChart = ({ data }) => {

  return (
    <div id="comparison-chart" className="aaa-comparison">
      <ContentBox>
        <div className="row no-gutters">
          <div className="col-12 col-lg-6 d-flex">
            <div className="aaa-comparison__title-container">
              <h2 className="aaa-comparison__title">{data.title}</h2>
              <div
                className="aaa-comparison__description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
              {data.disclaimers && (
                <div className='aaa-comparison__disclaimers'>
                  {data.disclaimers.map( (disclaimer, i) => (
                    <div
                      className='aaa-comparison__disclaimer'
                      dangerouslySetInnerHTML={{ __html: disclaimer }}
                      key={i}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="aaa-comparison__plans-container">
              <div className="row">
                <div className="aaa-comparison__logo-container">
                  <img
                    src={data.logo.publicURL}
                    className="aaa-comparison__logo"
                  />
                </div>
              </div>
              {features.map((feature, i) => (
                <div key={i} className="row">
                  {data.plans.map((plan, j) => (
                    <div
                      key={j}
                      className="aaa-comparison__col"
                    >
                      {feature({ plan, index: j })}
                    </div>
                  ))}
                </div>
              ))}
              <div className="aaa-comparison__box" />
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default ComparisonChart;
