import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import ComparisonChart from 'components/ComparisonChart/ComparisonChart';
import Hero from 'components/Hero/Hero';
import { HowItWorks } from 'components/HowItWorks';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import InfoBlock from 'components/InfoBlock';
import PreFooter from 'components/PreFooter/PreFooter';

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.indexYaml.title} titleTemplate="%s" />
      <Hero data={data.indexYaml.hero} />
      <HowItWorks data={data.indexYaml.howItWorks} />
      <hr />
      <Fragment>
        <InfoBlock data={data.indexYaml.compareSolutionsBlock} />
        <ComparisonChart data={data.indexYaml.comparisonChart} />
        {data.indexYaml.bigInfoCards.map((card, i) => (<InfoCardBig key={i} data={card} />))}
      </Fragment>
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    indexYaml {
      id
      title

      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        primaryButton {
          text
          url
        }
        separator
      }
      howItWorks {
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        items {
          title
          description
          link {
            text
            url
          }
        }
      }
      compareSolutionsBlock {
        title
        infoCards {
          title
          subtitle
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link {
            text
            url
          }
        }
      }
      comparisonChart {
        title
        description
        disclaimers
        logo {
          publicURL
        }
        plans {
          id
          title
          titleShort
          subtitle
          price
          billingPeriod
        }
      }
      bigInfoCards {
        color
        type
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 891, maxHeight: 521) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
